$(function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });


});


/* window.Echo.channel('call')
        .listen('.autodial', (e) => {
            console.log(e);
        });
 */

var channel = Echo.channel('chat');
channel.listen('.my‑channel', function (data) {

    var userId = $('meta[name="user-id"]').attr('content');
    console.log(data.user.id);
    if (userId == data.user.id) {
        /* var win = window.open('/leads/', '_blank');
        if (win) {
            win.focus();
        } */
        console.log("test");
        window.location.replace("/leads");

    }
});

$('.side-bar-toggle-res').on('click', function(event) {
    var x = document.getElementById("side-nav");

    if (x.style.display === "none") {
        x.style.display = "block";
    } else {
        x.style.display = "none";
    }
});

/* window.Echo.private('private-notif.${id}').listen('.my‑channel', function (e) {
    console.log(e);
    var win = window.open('http://copreus-crm.test/', '_blank');
    if (win) {
        win.focus();
    }

    alert('ela');

}); */