window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    window.moment = require('moment');

    require('bootstrap');
    require('@fortawesome/fontawesome-free');
    //require('bootstrap-material-design');
    require('bootstrap-daterangepicker');
    require('bootstrap-select');
    require('jsrender');
    require('bootstrap4-tagsinput-douglasanpa/tagsinput');
    require('bootstrap-toggle/js/bootstrap-toggle');
    //require('bs-stepper/dist/js/bs-stepper.js');
    require('bootstrap-slider/src/js/bootstrap-slider');
    //require('chart.js/dist/Chart');
    //require('dropzone');
    //require('bootstrap-confirm-delete/bootstrap-confirm-delete');
    //require('chart.js/dist/Chart.bundle');

    $.views.settings.delimiters("<%", "%>");
} catch (e) { }

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: '7df4f111930fb6360302',
    cluster: 'ap2',
    forceTLS: true,
  });

/* window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    forceTLS: true
});
 */
Pusher.logToConsole = true;

